<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask id="mask0_107_5507" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="currentColor"/>
    </mask>
    <g mask="url(#mask0_107_5507)">
      <path d="M8.91103 8.00004L5.33325 4.42226L6.42214 3.33337L11.0888 8.00004L6.42214 12.6667L5.33325 11.5778L8.91103 8.00004Z" fill="currentColor"/>
    </g>
  </svg>
</template>

<script setup
        lang="ts">

</script>

<style scoped>

</style>